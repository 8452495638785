@use "global" as *;
@use "semantic-shared" as *;
@use "semantic-light" as *;
@use "component-shared" as *;
@use "component-light" as *;

.ub-light {
    @include global-tokens;
    @include semantic-shared-tokens;
    @include semantic-light-tokens;
    @include component-shared-tokens;
    @include component-light-tokens;
    --g-ui-theme: light;
}
