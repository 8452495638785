@use "global" as *;

@mixin semantic-shared-tokens {
    --s-border-radius-field-standard: 0;
    --s-border-radius-field-small: 0;
    --s-border-radius-background-standard: 0;
    --s-border-radius-background-small: 0;
    --s-border-radius-interactive-focus: 0;

    --s-border-width-field-default: var(--g-border-width-xs);
    --s-border-width-field-active: var(--g-border-width-xs);
    --s-border-width-field-focus: var(--g-border-width-xs);
    --s-border-width-background-standard: var(--g-border-width-xs);

    --s-size-height-field-standard: var(--g-size-md);
    --s-size-height-field-small: var(--g-size-xs);
}
