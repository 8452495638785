@use "sass:map";
@use "component-shared" as *;
@use "global-color" as *;

@mixin component-light-tokens {
    :global {
        .button-primary-fallback-palette {
            --background-default: #{$g-color-primary-200};
            --background-active: #{$g-color-primary-200};
            --background-focus: #{$g-color-primary-200};
            --background-disabled: transparent;
            --border-color-default: #{$g-color-primary-900};
            --border-color-active: #{$g-color-primary-900};
            --border-color-focus: #{$g-color-primary-900};
            --border-color-disabled: var(--s-color-disabled-primary);
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-900};
            --icon-color-focus: #{$g-color-primary-900};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-primary-900};
            --text-color-default: #{$g-color-primary-900};
            --text-color-active: #{$g-color-primary-900};
            --text-color-focus: #{$g-color-primary-900};
            --text-color-disabled: var(--s-color-disabled-primary);
        }

        .button-secondary-fallback-palette {
            --background-default: transparent;
            --background-active: #{$g-color-monochrome-500};
            --background-focus: #{$g-color-monochrome-500};
            --background-disabled: transparent;
            --border-color-default: #{$g-color-monochrome-500};
            --border-color-active: #{$g-color-monochrome-500};
            --border-color-focus: #{$g-color-monochrome-500};
            --border-color-disabled: var(--s-color-disabled-primary);
            --icon-color-default: #{$g-color-monochrome-500};
            --icon-color-active: #{$g-color-monochrome-10};
            --icon-color-focus: #{$g-color-monochrome-10};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-monochrome-500};
            --text-color-default: #{$g-color-monochrome-500};
            --text-color-active: #{$g-color-monochrome-10};
            --text-color-focus: #{$g-color-monochrome-10};
            --text-color-disabled: var(--s-color-disabled-primary);
        }

        .button-quiet-fallback-palette {
            --background-default: transparent;
            --background-active: transparent;
            --background-focus: transparent;
            --background-disabled: transparent;
            --border-color-default: transparent;
            --border-color-active: transparent;
            --border-color-focus: transparent;
            --border-color-disabled: transparent;
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-500};
            --icon-color-focus: #{$g-color-primary-500};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-primary-500};
            --text-color-default: #{$g-color-primary-900};
            --text-color-active: #{$g-color-primary-500};
            --text-color-focus: #{$g-color-primary-500};
            --text-color-disabled: var(--s-color-disabled-primary);
        }

        .button-inverse-fallback-palette {
            --background-default: #{$g-color-monochrome-10};
            --background-active: #{$g-color-monochrome-100};
            --background-focus: #{$g-color-monochrome-100};
            --background-disabled: var(--s-color-disabled-primary);
            --border-color-default: transparent;
            --border-color-active: transparent;
            --border-color-focus: transparent;
            --border-color-disabled: transparent;
            --icon-color-default: #{$g-color-monochrome-600};
            --icon-color-active: #{$g-color-monochrome-700};
            --icon-color-focus: #{$g-color-monochrome-700};
            --icon-color-disabled: var(--s-color-disabled-secondary);
            --outline-color-focus: #{$g-color-monochrome-100};
            --text-color-default: #{$g-color-monochrome-600};
            --text-color-active: #{$g-color-monochrome-700};
            --text-color-focus: #{$g-color-monochrome-700};
            --text-color-disabled: var(--s-color-disabled-secondary);
        }

        .icon-button-primary-fallback-palette {
            --outline-color-focus: #{$g-color-primary-500};
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-600};
            --icon-color-focus: #{$g-color-primary-600};
        }

        .icon-button-secondary-fallback-palette {
            --background-color-default: transparent;
            --background-color-active: #{$g-color-monochrome-500};
            --background-color-focus: #{$g-color-monochrome-500};
            --border-color-default: #{$g-color-monochrome-500};
            --border-color-active: #{$g-color-monochrome-500};
            --border-color-focus: #{$g-color-monochrome-500};
            --outline-color-focus: #{$g-color-monochrome-500};
            --icon-color-default: #{$g-color-monochrome-500};
            --icon-color-active: #{$g-color-monochrome-10};
            --icon-color-focus: #{$g-color-monochrome-10};
        }

        .search-field-wrapper-standard-palette {
            --background-default: transparent;
            --background-active: transparent;
            --background-focus: transparent;
            --background-disabled: transparent;
            --border-color-default: transparent;
            --border-color-active: transparent;
            --border-color-focus: transparent;
            --border-color-disabled: transparent;
            --outline-color-focus: var(--s-color-field-border-focus);
            --icon-color-default: var(--s-color-icon-primary);
            --icon-color-active: var(--s-color-icon-primary);
            --icon-color-focus: var(--s-color-icon-primary);
            --icon-color-disabled: var(--s-color-disabled-primary);
        }

        .search-field-wrapper-quiet-palette {
            --background-default: #{$g-color-aperol-200};
            --background-active: #{$g-color-aperol-200};
            --background-focus: #{$g-color-aperol-200};
            --background-disabled: transparent;
            --border-color-default: #{$g-color-aperol-900};
            --border-color-active: #{$g-color-aperol-900};
            --border-color-focus: #{$g-color-aperol-900};
            --border-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: var(--s-color-field-border-focus);
            --icon-color-default: #{$g-color-aperol-900};
            --icon-color-active: #{$g-color-aperol-900};
            --icon-color-focus: #{$g-color-aperol-900};
            --icon-color-disabled: var(--s-color-disabled-primary);
        }

        @each $palette-name, $palette-values in $button-palettes {
            .button-primary-#{$palette-name}-palette {
                --background-default: #{map.get($palette-values, "200")};
                --background-active: #{map.get($palette-values, "200")};
                --background-focus: #{map.get($palette-values, "200")};
                --background-disabled: transparent;
                --border-color-default: #{map.get($palette-values, "900")};
                --border-color-active: #{map.get($palette-values, "900")};
                --border-color-focus: #{map.get($palette-values, "900")};
                --border-color-disabled: var(--s-color-disabled-primary);
                --icon-color-default: #{map.get($palette-values, "900")};
                --icon-color-active: #{map.get($palette-values, "900")};
                --icon-color-focus: #{map.get($palette-values, "900")};
                --icon-color-disabled: var(--s-color-disabled-primary);
                --outline-color-focus: #{map.get($palette-values, "900")};
                --text-color-default: #{map.get($palette-values, "900")};
                --text-color-active: #{map.get($palette-values, "900")};
                --text-color-focus: #{map.get($palette-values, "900")};
                --text-color-disabled: var(--s-color-disabled-primary);
            }

            .button-secondary-#{$palette-name}-palette {
                --background-default: transparent;
                --background-active: #{map.get($palette-values, "500")};
                --background-focus: #{map.get($palette-values, "500")};
                --background-disabled: transparent;
                --border-color-default: #{map.get($palette-values, "500")};
                --border-color-active: #{map.get($palette-values, "500")};
                --border-color-focus: #{map.get($palette-values, "500")};
                --border-color-disabled: var(--s-color-disabled-primary);
                --icon-color-default: #{map.get($palette-values, "500")};
                --icon-color-active: #{map.get($palette-values, "10")};
                --icon-color-focus: #{map.get($palette-values, "10")};
                --icon-color-disabled: var(--s-color-disabled-primary);
                --outline-color-focus: #{map.get($palette-values, "500")};
                --text-color-default: #{map.get($palette-values, "500")};
                --text-color-active: #{map.get($palette-values, "10")};
                --text-color-focus: #{map.get($palette-values, "10")};
                --text-color-disabled: var(--s-color-disabled-primary);
            }

            .button-quiet-#{$palette-name}-palette {
                --background-default: transparent;
                --background-active: transparent;
                --background-focus: transparent;
                --background-disabled: transparent;
                --border-color-default: transparent;
                --border-color-active: transparent;
                --border-color-focus: transparent;
                --border-color-disabled: transparent;
                --icon-color-default: #{map.get($palette-values, "900")};
                --icon-color-active: #{map.get($palette-values, "500")};
                --icon-color-focus: #{map.get($palette-values, "500")};
                --icon-color-disabled: var(--s-color-disabled-primary);
                --outline-color-focus: #{map.get($palette-values, "500")};
                --text-color-default: #{map.get($palette-values, "900")};
                --text-color-active: #{map.get($palette-values, "500")};
                --text-color-focus: #{map.get($palette-values, "500")};
                --text-color-disabled: var(--s-color-disabled-primary);
            }

            .button-inverse-#{$palette-name}-palette {
                --background-default: #{map.get($palette-values, "10")};
                --background-active: #{map.get($palette-values, "100")};
                --background-focus: #{map.get($palette-values, "100")};
                --background-disabled: var(--s-color-disabled-primary);
                --border-color-default: transparent;
                --border-color-active: transparent;
                --border-color-focus: transparent;
                --border-color-disabled: transparent;
                --icon-color-default: #{map.get($palette-values, "600")};
                --icon-color-active: #{map.get($palette-values, "700")};
                --icon-color-focus: #{map.get($palette-values, "700")};
                --icon-color-disabled: var(--s-color-disabled-secondary);
                --outline-color-focus: #{map.get($palette-values, "100")};
                --text-color-default: #{map.get($palette-values, "600")};
                --text-color-active: #{map.get($palette-values, "700")};
                --text-color-focus: #{map.get($palette-values, "700")};
                --text-color-disabled: var(--s-color-disabled-secondary);
            }

            .icon-button-primary-#{$palette-name}-palette {
                --icon-color-default: #{map-get($palette-values, "900")};
                --icon-color-active: #{map-get($palette-values, "600")};
                --icon-color-focus: #{map-get($palette-values, "600")};
                --outline-color-focus: #{map-get($palette-values, "500")};
            }

            .icon-button-secondary-#{$palette-name}-palette {
                --icon-color-default: #{map-get($palette-values, "500")};
                --icon-color-active: #{map-get($palette-values, "10")};
                --icon-color-focus: #{map-get($palette-values, "10")};
                --border-color-default: #{map-get($palette-values, "500")};
                --border-color-active: #{map-get($palette-values, "500")};
                --border-color-focus: #{map-get($palette-values, "500")};
                --outline-color-focus: #{map-get($palette-values, "500")};
                --background-color-default: transparent;
                --background-color-active: #{map-get($palette-values, "500")};
                --background-color-focus: #{map-get($palette-values, "500")};
            }

            .loading-spinner-standard-#{$palette-name}-palette {
                --loader-color-default: #{map-get($palette-values, "500")};
            }

            // Semantic palette overrides
            @if $palette-name ==
                "info" or
                $palette-name ==
                "alert" or
                $palette-name ==
                "success"
            {
                .button-secondary-#{$palette-name}-palette {
                    --background-active: #{map.get($palette-values, "600")};
                    --background-focus: #{map.get($palette-values, "600")};
                    --border-color-default: #{map.get($palette-values, "600")};
                    --border-color-active: #{map.get($palette-values, "600")};
                    --border-color-focus: #{map.get($palette-values, "600")};
                    --icon-color-default: #{map.get($palette-values, "600")};
                    --outline-color-focus: #{map.get($palette-values, "600")};
                    --text-color-default: #{map.get($palette-values, "600")};
                }
                .button-quiet-#{$palette-name}-palette {
                    --icon-color-active: #{map.get($palette-values, "600")};
                    --icon-color-focus: #{map.get($palette-values, "600")};
                    --outline-color-focus: #{map.get($palette-values, "600")};
                    --text-color-active: #{map.get($palette-values, "600")};
                    --text-color-focus: #{map.get($palette-values, "600")};
                }
            }
            @if $palette-name == "info" or $palette-name == "success" {
                .button-inverse-#{$palette-name}-palette {
                    --icon-color-active: #{map.get($palette-values, "600")};
                    --icon-color-focus: #{map.get($palette-values, "600")};
                    --text-color-active: #{map.get($palette-values, "600")};
                    --text-color-focus: #{map.get($palette-values, "600")};
                }
            }
            @if $palette-name == "alert" {
                .button-inverse-#{$palette-name}-palette {
                    --icon-color-default: #{map.get($palette-values, "700")};
                    --text-color-default: #{map.get($palette-values, "700")};
                }
            }

            @if $palette-name == "static-white" {
                .button-primary-#{$palette-name}-palette {
                    --background-default: #{map.get($palette-values, "10")};
                    --background-active: #{map.get($palette-values, "10")};
                    --background-focus: #{map.get($palette-values, "10")};
                    --background-disabled: transparent;
                    --border-color-default: #{map.get($palette-values, "900")};
                    --border-color-active: #{map.get($palette-values, "900")};
                    --border-color-focus: #{map.get($palette-values, "900")};
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "900")};
                    --icon-color-active: #{map.get($palette-values, "900")};
                    --icon-color-focus: #{map.get($palette-values, "900")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "900")};
                    --text-color-default: #{map.get($palette-values, "900")};
                    --text-color-active: #{map.get($palette-values, "900")};
                    --text-color-focus: #{map.get($palette-values, "900")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-secondary-#{$palette-name}-palette {
                    --background-default: transparent;
                    --background-active: #{map.get($palette-values, "10")};
                    --background-focus: #{map.get($palette-values, "10")};
                    --background-disabled: transparent;
                    --border-color-default: #{map.get($palette-values, "10")};
                    --border-color-active: #{map.get($palette-values, "10")};
                    --border-color-focus: #{map.get($palette-values, "10")};
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "10")};
                    --icon-color-active: #{map.get($palette-values, "900")};
                    --icon-color-focus: #{map.get($palette-values, "900")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "10")};
                    --text-color-default: #{map.get($palette-values, "10")};
                    --text-color-active: #{map.get($palette-values, "900")};
                    --text-color-focus: #{map.get($palette-values, "900")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-quiet-#{$palette-name}-palette {
                    --background-default: transparent;
                    --background-active: transparent;
                    --background-focus: transparent;
                    --background-disabled: transparent;
                    --border-color-default: transparent;
                    --border-color-active: transparent;
                    --border-color-focus: transparent;
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "10")};
                    --icon-color-active: #{map.get($palette-values, "10")};
                    --icon-color-focus: #{map.get($palette-values, "10")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "10")};
                    --text-color-default: #{map.get($palette-values, "10")};
                    --text-color-active: #{map.get($palette-values, "10")};
                    --text-color-focus: #{map.get($palette-values, "10")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-inverse-#{$palette-name}-palette {
                    --background-default: #{map.get($palette-values, "10")};
                    --background-active: #{map.get($palette-values, "10")};
                    --background-focus: #{map.get($palette-values, "10")};
                    --background-disabled: var(--s-color-disabled-primary);
                    --border-color-default: #{map.get($palette-values, "10")};
                    --border-color-active: #{map.get($palette-values, "10")};
                    --border-color-focus: #{map.get($palette-values, "10")};
                    --border-color-disabled: transparent;
                    --icon-color-default: #{map.get($palette-values, "900")};
                    --icon-color-active: #{map.get($palette-values, "900")};
                    --icon-color-focus: #{map.get($palette-values, "900")};
                    --icon-color-disabled: var(--s-color-disabled-secondary);
                    --outline-color-focus: #{map.get($palette-values, "10")};
                    --text-color-default: #{map.get($palette-values, "900")};
                    --text-color-active: #{map.get($palette-values, "900")};
                    --text-color-focus: #{map.get($palette-values, "900")};
                    --text-color-disabled: var(--s-color-disabled-secondary);
                }
            }

            @if $palette-name == "static-black" {
                .button-primary-#{$palette-name}-palette {
                    --background-default: #{map.get($palette-values, "900")};
                    --background-active: #{map.get($palette-values, "900")};
                    --background-focus: #{map.get($palette-values, "900")};
                    --background-disabled: transparent;
                    --border-color-default: #{map.get($palette-values, "10")};
                    --border-color-active: #{map.get($palette-values, "10")};
                    --border-color-focus: #{map.get($palette-values, "10")};
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "10")};
                    --icon-color-active: #{map.get($palette-values, "10")};
                    --icon-color-focus: #{map.get($palette-values, "10")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "10")};
                    --text-color-default: #{map.get($palette-values, "10")};
                    --text-color-active: #{map.get($palette-values, "10")};
                    --text-color-focus: #{map.get($palette-values, "10")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-secondary-#{$palette-name}-palette {
                    --background-default: transparent;
                    --background-active: #{map.get($palette-values, "900")};
                    --background-focus: #{map.get($palette-values, "900")};
                    --background-disabled: transparent;
                    --border-color-default: #{map.get($palette-values, "900")};
                    --border-color-active: #{map.get($palette-values, "900")};
                    --border-color-focus: #{map.get($palette-values, "900")};
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "900")};
                    --icon-color-active: #{map.get($palette-values, "10")};
                    --icon-color-focus: #{map.get($palette-values, "10")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "900")};
                    --text-color-default: #{map.get($palette-values, "900")};
                    --text-color-active: #{map.get($palette-values, "10")};
                    --text-color-focus: #{map.get($palette-values, "10")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-quiet-#{$palette-name}-palette {
                    --background-default: transparent;
                    --background-active: transparent;
                    --background-focus: transparent;
                    --background-disabled: transparent;
                    --border-color-default: transparent;
                    --border-color-active: transparent;
                    --border-color-focus: transparent;
                    --border-color-disabled: var(--s-color-disabled-primary);
                    --icon-color-default: #{map.get($palette-values, "900")};
                    --icon-color-active: #{map.get($palette-values, "900")};
                    --icon-color-focus: #{map.get($palette-values, "900")};
                    --icon-color-disabled: var(--s-color-disabled-primary);
                    --outline-color-focus: #{map.get($palette-values, "900")};
                    --text-color-default: #{map.get($palette-values, "900")};
                    --text-color-active: #{map.get($palette-values, "900")};
                    --text-color-focus: #{map.get($palette-values, "900")};
                    --text-color-disabled: var(--s-color-disabled-primary);
                }

                .button-inverse-#{$palette-name}-palette {
                    --background-default: #{map.get($palette-values, "900")};
                    --background-active: #{map.get($palette-values, "900")};
                    --background-focus: #{map.get($palette-values, "900")};
                    --background-disabled: var(--s-color-disabled-primary);
                    --border-color-default: #{map.get($palette-values, "900")};
                    --border-color-active: #{map.get($palette-values, "900")};
                    --border-color-focus: #{map.get($palette-values, "900")};
                    --border-color-disabled: transparent;
                    --icon-color-default: #{map.get($palette-values, "10")};
                    --icon-color-active: #{map.get($palette-values, "10")};
                    --icon-color-focus: #{map.get($palette-values, "10")};
                    --icon-color-disabled: var(--s-color-disabled-secondary);
                    --outline-color-focus: #{map.get($palette-values, "900")};
                    --text-color-default: #{map.get($palette-values, "10")};
                    --text-color-active: #{map.get($palette-values, "10")};
                    --text-color-focus: #{map.get($palette-values, "10")};
                    --text-color-disabled: var(--s-color-disabled-secondary);
                }
            }
        }

        .check-button-default-palette {
            --background-default: #{$g-color-primary-500};
            --background-active: #{$g-color-primary-600};
            --background-focus: #{$g-color-primary-600};
            --background-selected: #{$g-color-primary-50};
            --background-disabled: var(--s-color-disabled-primary);
            --border-color-default: transparent;
            --border-color-active: transparent;
            --border-color-focus: transparent;
            --border-color-disabled: transparent;
            --icon-color-default: #{$g-color-primary-10};
            --icon-color-active: #{$g-color-primary-10};
            --icon-color-focus: #{$g-color-primary-10};
            --icon-color-selected: #{$g-color-primary-600};
            --icon-color-disabled: var(--s-color-disabled-secondary);
            --outline-color-focus: #{$g-color-primary-600};
            --text-color-default: #{$g-color-primary-10};
            --text-color-active: #{$g-color-primary-10};
            --text-color-focus: #{$g-color-primary-10};
            --text-color-selected: #{$g-color-primary-600};
            --text-color-disabled: var(--s-color-disabled-secondary);
        }

        .calendar-nav-button-default-palette {
            --background-default: transparent;
            --background-active: #{$g-color-primary-50};
            --background-focus: transparent;
            --background-disabled: transparent;
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-600};
            --icon-color-focus: #{$g-color-primary-600};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-primary-500};
        }

        .slider-button-standard-palette {
            --background-default: #{$g-color-primary-200};
            --background-active: #{$g-color-primary-300};
            --background-focus: #{$g-color-primary-300};
            --background-disabled: var(--s-color-disabled-secondary);
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-900};
            --icon-color-focus: #{$g-color-primary-900};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-primary-500};
        }

        .slider-button-quiet-palette {
            --background-default: transparent;
            --background-active: #{$g-color-primary-50};
            --background-focus: transparent;
            --background-disabled: transparent;
            --icon-color-default: #{$g-color-primary-900};
            --icon-color-active: #{$g-color-primary-600};
            --icon-color-focus: #{$g-color-primary-600};
            --icon-color-disabled: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-primary-500};
        }

        .slider-button-quiet-inverse-palette {
            --background-default: #{$g-color-black};
            --background-active: #{$g-color-black};
            --background-focus: #{$g-color-black};
            --background-disabled: #{$g-color-black};
            --icon-color-default: #{$g-color-primary-10};
            --icon-color-active: #{$g-color-primary-100};
            --icon-color-focus: #{$g-color-primary-100};
            --icon-color-disabled: #{$g-color-primary-100};
            --outline-color-focus: #{$g-color-primary-100};
        }

        .dot-indicator-standard-palette {
            --background-default: transparent;
            --background-active: var(--s-color-icon-primary);
            --background-focus: var(--s-color-icon-primary);
            --background-selected: var(--s-color-icon-primary);
            --background-disabled-default: transparent;
            --background-disabled-selected: var(--s-color-disabled-primary);
            --border-color-default: var(--s-color-icon-primary);
            --border-color-active: var(--s-color-icon-primary);
            --border-color-focus: var(--s-color-icon-primary);
            --border-color-selected: var(--s-color-icon-primary);
            --border-color-disabled-default: var(--s-color-disabled-primary);
            --border-color-disabled-selected: var(--s-color-disabled-primary);
            --outline-color-focus: var(--s-color-icon-primary);
        }

        .dot-indicator-inverse-palette {
            --background-default: transparent;
            --background-active: #{$g-color-neutral-10};
            --background-focus: #{$g-color-neutral-10};
            --background-selected: #{$g-color-neutral-10};
            --background-disabled-default: transparent;
            --background-disabled-selected: var(--s-color-disabled-primary);
            --border-color-default: #{$g-color-neutral-10};
            --border-color-active: #{$g-color-neutral-10};
            --border-color-focus: #{$g-color-neutral-10};
            --border-color-selected: #{$g-color-neutral-10};
            --border-color-disabled-default: var(--s-color-disabled-primary);
            --border-color-disabled-selected: var(--s-color-disabled-primary);
            --outline-color-focus: #{$g-color-neutral-10};
        }
    }

    //uri encoding is required here bc it breaks the gat-web build if left unencoded. 🩹
    --c-celebration-success-asset-source: #{"https%3A%2F%2Fassets.efcore.services%2Fassets%2Flottie-illustration-ub-thumbsup-legacy.json"};
    --c-celebration-info-asset-source: #{"https%3A%2F%2Fassets.efcore.services%2Fassets%2Flottie-illustration-ub-fireworks-legacy.json"};

    --c-loading-animation-plane-window-asset-source: #{"https%3A%2F%2Fassets.efcore.services%2Fassets%2Flottie-icon-loading-plane-window-legacy.json"};

    --c-pagination-button-background-active: #{$g-color-primary-50};
    --c-pagination-button-background-focus: #{$g-color-primary-50};
    --c-pagination-button-icon-color-active: #{$g-color-primary-600};
    --c-pagination-button-icon-color-default: #{$g-color-primary-900};
    --c-pagination-button-icon-color-focus: #{$g-color-primary-600};
    --c-pagination-button-outline-color-focus: #{$g-color-primary-500};

    --c-rating-standard-icon-color: #{$g-color-aperol-400};

    --c-rating-field-icon-color-default: #{$g-color-aperol-900};
    --c-rating-field-icon-color-active: #{$g-color-aperol-400};
    --c-rating-field-icon-color-focus: #{$g-color-aperol-400};
    --c-rating-field-icon-color-selected: #{$g-color-aperol-900};
    --c-rating-field-outline-color-focus: #{$g-color-aperol-400};

    --c-markdown-link-color-override-active: brightness(0.8);
    --c-markdown-link-color-override-focus: brightness(0.8);

    --c-status-label-primary-urgent-background: #{$g-color-butter-200};
    --c-status-label-primary-important-background: #{$g-color-bubblegum-200};
    --c-status-label-secondary-urgent-text-color: #{$g-color-butter-500};
    --c-status-label-secondary-urgent-border-color: #{$g-color-butter-300};
    --c-status-label-secondary-important-text-color: #{$g-color-bubblegum-500};
    --c-status-label-secondary-important-border-color: #{$g-color-bubblegum-300};

    --c-footer-background-color: #{var(--g-color-ink-black)};

    --c-chat-button-background-default: #{$g-color-primary-500};
    --c-chat-button-background-active: #{$g-color-primary-600};
    --c-chat-button-background-focus: #{$g-color-primary-600};
    --c-chat-button-background-disabled: var(--s-color-disabled-primary);
    --c-chat-button-border-color-default: transparent;
    --c-chat-button-border-color-active: transparent;
    --c-chat-button-border-color-focus: transparent;
    --c-chat-button-border-color-disabled: transparent;
    --c-chat-button-icon-color-default: #{$g-color-primary-10};
    --c-chat-button-icon-color-active: #{$g-color-primary-10};
    --c-chat-button-icon-color-focus: #{$g-color-primary-10};
    --c-chat-button-icon-color-disabled: var(--s-color-disabled-secondary);
    --c-chat-button-outline-color-focus: #{$g-color-primary-600};
    --c-chat-button-text-color-default: #{$g-color-primary-10};
    --c-chat-button-text-color-active: #{$g-color-primary-10};
    --c-chat-button-text-color-focus: #{$g-color-primary-10};
    --c-chat-button-text-color-disabled: var(--s-color-disabled-secondary);
}
